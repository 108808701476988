<template>
  <section class="invoice-preview-wrapper">

    <b-row
        v-if="order!=null"
        class="invoice-preview"
    >

      <!-- Col: Left (Invoice Container) -->
      <b-col
          order="2"
          order-md="1"
          cols="12"
          xl="9"
          md="8"
      >

        <view-order
            ref="orderView"
            :order="order"
        />
      </b-col>

      <!-- Right Col: Card -->
      <b-col
          order="1"
          order-md="2"
          cols="12"
          md="4"
          xl="3"
          class="offer-actions"
      >
        <buttons-col
            :order="order"
            @validateOrder="validateOrderLocal(order)"
            @acceptOrder="acceptOrderLocal(order)"
            @duplicateOrder="duplicateOrder(order)"
            @removeOrder="deleteOrderAlert(order)"
            @downloadOrder="downloadOrder(order)"
            @downloadOrderIncomingInvoice="downloadOrderIncomingInvoice(order)"
            @billOrder="billOrder(order)"
            @archivateOrder="archivateOrder(order)"
            @unarchivateOrder="unarchivateOrder(order)"
        />

        <documents-col
            :order="order"
            @downloadOrder="downloadOrder"
            @editIncomingInvoice="editIncomingInvoice(order, $event)"
            @deleteIncomingInvoice="deleteIncomingInvoiceAlert(order, $event)"
            :key="componentKey"
        />
      </b-col>
    </b-row>

    <!-- modal order-->
    <modal-order
        :order.sync="currentOrder"
        :title="orderModalTitle"
        :isOpen.sync="orderModalShow"
        @submitValidatedOrder="submitValidatedOrderLocal"
    />

    <!-- modal order validation-->
    <modal-order-validation
        :title="$t('OrderValidation')"
        :offer.sync="currentOrder"
        :isOpen.sync="isOrderAcceptanceModalOpen"
        @submitValidatedOfferValidationForm="submitValidatedOrderLocal"
    />

    <!-- modal upload incoming invoice -->
    <modal-upload-incoming-invoice
        :singleFile.sync="newIncomingInvoiceFile"
        :documentType="'incomingInvoice'"
        :title="capitalize($tc('invoice'))"
        :isOpen.sync="incomingInvoiceByUploadModalShow"
    />

    <!-- modal incoming invoice-->
    <modal-incoming-invoice
        :incomingInvoice.sync="newIncomingInvoice"
        :title="incomingInvoiceModalTitle"
        :isOpen.sync="incomingInvoiceModalShow"
        @submitValidatedIncomingInvoice="submitValidatedIncomingInvoiceLocal"
    />

  </section>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { isObject } from '@/utils/utils'

import { mixinOrders } from './mixinOrders'
import store from '@/store'
import router from '@/router'
import ViewOrder from '@/components/views/Order'
import ButtonsCol from './components/_buttons_col'
import DocumentsCol from './components/_documents_col'
import ModalOrder from '@/components/prompt/Order'
import ModalUploadIncomingInvoice from '@/components/prompt/Upload'
import ModalIncomingInvoice from '@/components/prompt/IncomingInvoice'

export default {
  components: {
    ViewOrder,
    ButtonsCol,
    DocumentsCol,
    ModalOrder,
    ModalUploadIncomingInvoice,
    ModalIncomingInvoice
  },
  mixins: [mixinOrders],
  props: {},
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const order = computed(() => {
      return store.getters['order/getOrder'](router.history.current.params.orderId)
    })

    const isReady = computed(() => (order.value))

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data

      // Computed
      order,
      isReady,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>