<template>
  <div>
    <b-card
        no-body
        class="invoice-preview-card position-relative"
        v-if="isMounted"
    >
      <div
          class="position-absolute h-100 w-100 overflow-hidden"
          :style="'z-index: '+(order.isArchived?2:0)"
      >
        <div class="d-flex h-100">
          <div class="m-auto">
            <p style="font-size:45px; transform:rotate(310deg);">
              <span v-if="order.isArchived"
                    class="text-warning">{{ $t('ARCHIVED') }}
              </span>
              <span v-else-if="order.number == null"
                    style="color:#f1f1f1;"
                    class="">{{ $t('DRAFT') }}
              </span>
            </p>
            <!--            <p style="font-size:120px;transform:rotate(310deg);color:#f1f1f1;">{{ $t('DRAFT') }}</p>-->
          </div>
        </div>
      </div>

      <div style="z-index:1"
           :class="{'bg-disabled': order.isArchived}">
        <!-- Header -->
        <b-card-body class="invoice-padding pb-0">

          <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

            <!-- Header: Left Content -->
<!--            <div v-if="myCompany">-->
<!--              <div class="logo-wrapper">-->
<!--                <b-img-->
<!--                    :src="require('@/assets/images/logo/logo.png')"-->
<!--                    alt="logo"-->
<!--                />-->
<!--                <h3 class="text-primary invoice-logo">-->
<!--                  {{ myCompany.name }}-->
<!--                </h3>-->
<!--              </div>-->
<!--              <p-->
<!--                  v-if="myCompany.addresses.length"-->
<!--                  class="card-text mb-25"-->
<!--              >-->
<!--                {{ myCompany.addresses[0]._display }}-->
<!--              </p>-->
<!--              <p-->
<!--                  v-if="myCompany.addresses.length"-->
<!--                  class="card-text mb-25"-->
<!--              >-->
<!--                {{ $store.getters['address/getCountry'](myCompany.addresses[0].country) }}-->
<!--              </p>-->
<!--              <p-->
<!--                  v-if="myCompany.phone"-->
<!--                  class="card-text mb-0"-->
<!--              >-->
<!--                {{ myCompany.phone }}-->
<!--              </p>-->
<!--            </div>-->
            <div>
              <h4>{{ order.supplierCompany._display }}</h4>
            </div>

            <!-- Header: Right Content -->
            <div class="mt-md-0 mt-2">
              <h4 class="invoice-title">{{ capitalize($tc('order')) }}
                <span v-if="order.number"
                      class="invoice-number">#{{ order.number }}
                </span>
              </h4>
              <!--              <div class="invoice-date-wrapper">-->
              <!--                <p class="invoice-date-title">-->
              <!--                  {{ $t('OrderBillingDate:') }}-->
              <!--                </p>-->
              <!--                <p class="invoice-date">-->
              <!--                  {{ order.billingDate|moment('L') }}-->
              <!--                </p>-->
              <!--              </div>-->
              <!--              <div class="invoice-date-wrapper">-->
              <!--                <p class="invoice-date-title">-->
              <!--                  {{ $t('DueDate:') }}-->
              <!--                </p>-->
              <!--                <p class="invoice-date">-->
              <!--                  {{ order._dueDate|moment('L') }}-->
              <!--                </p>-->
              <!--              </div>-->
            </div>
          </div>
        </b-card-body>

        <!-- Spacer -->
        <hr class="invoice-spacing">

        <!-- Invoice Client & Payment Details -->
<!--        <b-card-body-->
<!--            class="invoice-padding pt-0"-->
<!--        >-->
<!--          <b-row class="mt-3 mb-1">-->
<!--            <b-col cols="12">-->
<!--              <b>{{ order.supplierCompany._display }}</b>-->
<!--            </b-col>-->
<!--          </b-row>-->
<!--        </b-card-body>-->

        <!-- Invoice Description: Table -->
        <b-table-lite
            responsive
            :items="order.products"
            :fields="tableColumns"
        >
          <template #head(toInvoice)="data">


            <div class="d-flex justify-content-between">
              <b-form-checkbox
                  v-b-tooltip.hover.right="$t('toInvoice')"
                  v-model="selectAll"
                  :disabled="isSelectAllDisabled"
              />
              <div v-if="selectedProductsLocal.length">
                <span class="text-uppercase">{{ $t('quantity') }}</span>
              </div>
            </div>

          </template>
          <template #cell(toInvoice)="data">
            <div class="d-flex justify-content-between">
              <b-form-checkbox
                  v-model="selectedProductsLocal"
                  :value="data.item"
                  :disabled="data.item._remaining <= 0"
              />
              <div v-if="selectedProductsLocal.some(p=>p.id == data.item.id)">
                <field-input
                    :name="'selectedQuantity'"
                    :listIndex="data.item.id"
                    :displayLabel="false"
                    :rules="'required'"
                    :model.sync="data.item._selectedQuantity"
                    :key="componentKey"
                />
              </div>
            </div>


          </template>

          <template #cell(article)="data">
            <b-card-text class="font-weight-bold mb-0">
              {{ data.item.article.name }}
            </b-card-text>
            <b-card-text v-if="data.item.article.supplierReference != null"
                         class="text-nowrap">
              <small>
                <i>{{ data.item.article.supplierReference }}</i>
              </small>
            </b-card-text>
            <b-card-text class="text-nowrap mt-25">
              <div v-html="data.item.article.description"></div>
            </b-card-text>
          </template>

          <template #cell(remaining)="data">
            <b-card-text class="">
              {{ data.item._remaining }}
            </b-card-text>
          </template>

          <template #cell(unitPrice)="data">
            <b-card-text class="">
              {{ currency(data.item.unitPrice) }}
            </b-card-text>
          </template>

          <template #cell(discount)="data">
            <b-card-text
                v-if="data.item.discount != null"
                class=""
            >
              <span v-if="data.item.isDiscountPercent">{{ percent(data.item.discount) }}</span>
              <span v-else>{{ currency(data.item.discount) }}</span>
            </b-card-text>
            <b-card-text
                v-else
                class=""
            >
              -
            </b-card-text>
          </template>

          <template #cell(preTaxAmount)="data">
            <b-card-text>
              <div v-if="data.item._remaining != null">
                {{ currency(data.item.unitPrice*data.item._remaining) }}
              </div>
              <div v-else>
                {{ currency(data.item.preTaxAmount) }}
              </div>
            </b-card-text>
          </template>
        </b-table-lite>

        <!-- Invoice Description: Total -->
        <b-card-body class="invoice-padding pb-2">
          <b-row>

            <!-- Col: Sales Persion -->
            <b-col
                cols="12"
                md="6"
                class="mt-md-0 mt-3"
                order="2"
                order-md="1"
            >

            </b-col>

            <!-- Col: Total -->
            <b-col
                cols="12"
                md="6"
                class="mt-md-6 d-flex justify-content-end"
                order="1"
                order-md="2"
            >
              <div class="invoice-total-wrapper">

                <!--            Discount-->
                <div
                    v-if="order.discount"
                    class="invoice-total-item"
                >
                  <p class="invoice-total-title">
                    {{ $t('Discount:') }}
                  </p>
                  <p class="invoice-total-amount">
                    <span v-if="order.isDiscountPercent">{{ percent(order.discount) }}</span>
                    <span v-else>{{ currency(order.discount) }}</span>
                  </p>
                </div>

                <!--            Pre tax amount-->
                <div class="invoice-total-item">
                  <p class="invoice-total-title">
                    {{ $t('PreTaxAmount:') }}
                  </p>
                  <p class="invoice-total-amount">
                    {{ currency(order.preTaxAmount) }}
                  </p>
                </div>

                <!--            Vat-->
                <div class="invoice-total-item">
                  <p class="invoice-total-title">
                    {{ $t('VAT:') }}
                  </p>
                  <p class="invoice-total-amount">
                    {{ currency(order.vat) }}
                  </p>
                </div>

                <!--            Total amount-->
                <hr class="my-50">
                <div class="invoice-total-item">
                  <p class="invoice-total-title">
                    {{ $t('TotalAmount:') }}
                  </p>
                  <p class="invoice-total-amount">
                    {{ currency(order.totalAmount) }}
                  </p>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-card-body>

        <!-- Spacer -->
        <!--        <hr class="invoice-spacing">-->

        <!-- Note -->
        <!--        <b-card-body class="invoice-padding pt-0">-->
        <!--          <span>-->
        <!--            <b>{{ $t('PaymentMethod:') }}</b> &nbsp;-->
        <!--          </span>-->
        <!--          <span>{{ $t(order.paymentMethod.name) }}</span>-->
        <!--        </b-card-body>-->
      </div>

    </b-card>
  </div>
</template>

<script>
import { ref, computed, watch, onMounted } from '@vue/composition-api'
import { capitalize, currency, percent } from '@/utils/filter'

import useAPI from '@/utils/useAPI'
import i18n from '@/libs/i18n'
import FieldInput from '@/components/input/Input'

export default {
  components: {
    FieldInput
  },
  props: {
    order: {
      type: Object,
      default: () => {}
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const tableColumns = ref([
      { key: 'article', label: i18n.t('article'), sortable: true, thClass: 'w-100', tdClass: '' },
      { key: 'quantity', label: i18n.t('quantity'), sortable: false, thClass: 'text-nowrap', tdClass: 'text-nowrap' },
      { key: 'unitPrice', label: i18n.t('unitPrice'), sortable: false, thClass: 'text-nowrap', tdClass: 'text-nowrap' },
      { key: 'discount', label: i18n.t('discount'), sortable: true, thClass: 'text-nowrap', tdClass: 'text-nowrap' },
      {
        key: 'preTaxAmount',
        label: i18n.t('preTaxAmount'),
        sortable: true,
        thClass: 'text-nowrap',
        tdClass: 'text-nowrap'
      },
    ])
    const selectAll = ref(false)
    const selectedProductsLocal = ref([])
    const componentKey = ref(0)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { myCompany, getCountry } = useAPI()

    const isSelectAllDisabled = computed(() => {
      return !props.order.products.some(p => parseFloat(p._remaining) > 0)
    })

    const selectableProducts = computed(() => {
      if (isDisabled == true) return []
      else return props.order.products.filter(p => parseFloat(p._remaining) > 0)
    })

    const isDisabled = computed(() => {
      return false
      // return props.offer.isDeclined || props.offer.isArchived || props.offer.isCanceled
    })

    const allowSelectProducts = computed(() => {
      return (props.order.signedDocument != null ||
              props.order.signedDocumentDate != null ||
              props.order.signedDocumentNumber != null
          ) &&
          isDisabled.value == false
    })

    const isMounted = computed(() => {
      return ('discount' in props.order)
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(selectAll, (val) => {
      if (val == true) {
        selectedProductsLocal.value = selectableProducts.value

      } else if (
          selectedProductsLocal.value.length == selectableProducts.value.length &&
          selectableProducts.value.length != 0
      ) {
        selectedProductsLocal.value = []
      }
    })

    watch(selectedProductsLocal, (val) => {
      // console.log(val)
      if (val.length != selectableProducts.value.length) selectAll.value = false
      else selectAll.value = true

      resetSelectedQuantity()
      val.forEach(selectedProduct => {
        if (!('_selectedQuantity' in selectedProduct)) {
          selectedProduct._selectedQuantity = JSON.parse(JSON.stringify(selectedProduct._remaining))
        }
      })

      console.log(val)

      // emit('update:selectedProducts', val)
      componentKey.value++
    })

    watch(isMounted, val => {
      if (val== true) {
        if (props.order._linkedIncomingInvoices.length) {
          if (allowSelectProducts) {
            tableColumns.value.splice(3, 0, { key: 'remaining', label: i18n.t('remaining'), sortable: true })
          } else {
            tableColumns.value.splice(2, 0, { key: 'remaining', label: i18n.t('remaining'), sortable: true })
          }
        }
      }
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const resetSelectedQuantity = () => {
      selectableProducts.value.forEach(sp => {
        if (!selectedProductsLocal.value.some(p => p.id == sp.id)) {
          delete sp._selectedQuantity
        }
      })
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    if (allowSelectProducts.value) {
      tableColumns.value.unshift({ key: 'toInvoice', sortable: false })
    }

    // if (props.order._linkedIncomingInvoices.length) {
    //   if (allowSelectProducts) {
    //     tableColumns.value.splice(3, 0, { key: 'remaining', label: i18n.t('remaining'), sortable: true })
    //   } else {
    //     tableColumns.value.splice(2, 0, { key: 'remaining', label: i18n.t('remaining'), sortable: true })
    //   }
    // }

    return {
      // Components
      capitalize,
      currency,
      percent,

      // Data
      tableColumns,
      // productsTableHeader,
      selectAll,
      selectedProductsLocal,
      componentKey,

      // Computed
      myCompany,
      getCountry,
      isSelectAllDisabled,
      selectableProducts,
      isDisabled,
      allowSelectProducts,
      isMounted,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {
    selectedProducts: function (val) {
      this.selectedProductsLocal = val

      if (this.selectableProducts.length == 0) this.selectAll = false
    },
    order: {
      deep: true,
      handler () {
        // console.log(this.order)
        if ((
                this.order.signedDocument != null ||
                this.order.signedDocumentDate != null ||
                this.order.signedDocumentNumber != null
            ) &&
            this.isDisabled == false) {
          this.tableColumns.unshift({ key: 'toInvoice', sortable: false })
        } else {
          if (this.tableColumns.length == 7) {
            this.tableColumns.shift()
            this.tableColumns.shift()
          }
        }

        if (
            this.order._linkedIncomingInvoices.length &&
            !this.tableColumns.some(c=>c.key == 'remaining')) {
          this.selectedProductsLocal = []
          if (this.allowSelectProducts) {
            this.tableColumns.splice(3, 0, { key: 'remaining', label: i18n.t('remaining'), sortable: true })
          } else {
            this.tableColumns.splice(2, 0, { key: 'remaining', label: i18n.t('remaining'), sortable: true })
          }
        }

      }
    }
  },
  methods: {},
  mounted () {
    // console.log(this.tableColumns)
  },
  created () {
  }
}
</script>

<style lang="scss"
       scoped>
@import "~@/assets/scss/base/pages/invoice.scss";
</style>


<style lang="scss">
@media print {

  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
